import React, { useEffect, useState } from 'react';
import {api_url} from '../Config';
  const lang =JSON.parse(sessionStorage.getItem("lang"));
const Footer = (props) =>{ 
  const [number, setNumber] = useState('');
  useEffect(() => {
    var WHATSAPPURL = api_url.base_url + 'customer_api.php?type=whatsappNo';
    
  fetch(WHATSAPPURL)
      .then(res => res.json())
      .then(result => {
        setNumber("https://wa.me/"+result.number)
      })
  }, [])
	return(

			<React.Fragment>
				<div className="bottom_bar">
                <ul>

                  <li style={{width: "27%"}}>
                    <a href="dashboard.html">
                      <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
                    <b>{lang.home}</b>
                    </a>
                  </li>
                  <li>
                    <a href="submit-pickups.html">
                      <svg viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#fff"/></svg>
                    <b>{lang.scan_pickup}</b>
                    </a>
                  </li>
                  <li>
                    <a href="submit-deliveries.html">
                      <svg viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#fff"/></svg>
                    <b>{lang.scan_delivery}</b>
                    </a>
                  </li>
                </ul>
                <div className="whatsapp">
        <a href={number} target="_blank"><i className="fa fa-whatsapp"></i></a>
      </div>
                </div>
			</React.Fragment>
		)
}
export default Footer
