import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../assets/images/loader-bg.jpg';
import loginScreen from '../assets/images/login-screen.jpg';
import site_logo from '../assets/images/white-black-logo.png';
import loader from '../assets/images/770.gif';
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config';
import swal from 'sweetalert';
import {english} from '../component/English';
import {french} from '../component/French';
import CommonHeader from '../component/CommonHeader';
import Footer from './Footer';
class Home extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        order_id:'',
        orderData:[],
        historyData:[],
        idErr:'',
        track_no:'',
        site_logo:'',
        company_name:'',
        english:english,
        french:french,
    };
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name ==null) {
       localStorage.setItem('lang',JSON.stringify(this.state.english));
       localStorage.setItem('lang_name','english');
    }

    this.onChange = this.onChange.bind(this);
    this.getShipmentDetails = this.getShipmentDetails.bind(this);
    var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo,
                    company_name:result.company_name
                })

            }
        })
}
componentDidMount(){

}
getShipmentDetails(e) {
    e.preventDefault();
    if (this.state.order_id==='') {
      this.setState({
        idErr:'Please enter Track No.'
      })
    }else{

      this.setState({loading:false})

      const sendData = {
          track_no:this.state.order_id
      }
      const trackURL = this.state.base_url+'api.php?type=track_shipments';
      fetch(trackURL,
      {
          method: 'POST',
          headers:
          {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(sendData)
      })
      .then((response) => response.json())
      .then(result=>{
       if(result.response===1)
       {
           this.setState({
               orderData:result.result,
               historyData:result.history,
               track_no:result.result[0].order_detail.track_no

           },()=>{
               this.setState({redirectToReferrer:true})
           });
       }else{
         swal('No Record Found.');
       }
       this.setState({loading:false});
      })
      .catch((error) => {
          this.setState({loading:false});
      });
    }
}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}

render(){
  let lang_name = localStorage.getItem('lang_name');
    if (lang_name ==null) {
       localStorage.setItem('lang',JSON.stringify(this.state.english));
       localStorage.setItem('lang_name','english');
    }
  const lang = JSON.parse(localStorage.getItem("lang")) ? JSON.parse(localStorage.getItem("lang")) : [];
const {loading} = this.state;
    if (this.state.redirectToReferrer) {
      return(<Redirect to={`/track-detail/${this.state.track_no}`}
          />)
    }
    if(localStorage.getItem('customer_id') > 0 )
      {
        return(<Redirect to='dashboard' />)
      }
return(

<React.Fragment>
  <div className="home_bg">
  <div className="main_body register_main" id="desktop_view">

<div className="track_reach" id="homejs_page">

    <CommonHeader />


    {loading ?
        ''
        :
         <React.Fragment>

    <div className="shipment_box desktop_layout " id="track_parcel_info">
    <form onSubmit={this.getShipmentDetails}>
       <div className="shipment_track_btns">
          <input type="text" placeholder={lang.enter_your_tracking_code} value={this.state.order_id} name="order_id" onChange={this.onChange} />
              <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#626262"></path></svg>
              <span>{this.state.idErr}</span>
              <input type="submit" className="login_btn_ shipment_btns mb-0 ml-2 mt-0 mr-2 w-75" value={lang.track_order}  />
        </div>
    </form>
      
      {
    //     <div className="login_btm">
    //   <img src={loginScreen} alt=""/>
    // </div>
      }
    </div>
    </React.Fragment>
}
  </div>
</div>
</div>

<Footer />

</React.Fragment>
  )
}
}
export default Home;
